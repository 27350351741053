const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6957071",
    space_js: "//afzbdm.moddydata.com/site/nihq/fj/static/oj-r.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957072",
    space_js: "//afzbdm.moddydata.com/production/o/static/jirg/openjs/k/production/pk/y.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957074",
    space_js: "//afzbdm.moddydata.com/site/q/common/lk/openjs/tim_r/source/mh.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957075",
    space_js: "//afzbdm.moddydata.com/common/rm_l/u_jns/common/nj.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6957073",
    space_js: "//afzbdm.moddydata.com/site/pk/static/js_h/resource/lqlc.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
